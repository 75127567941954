const state = {
}

const actions = {

    // CONTACTS

    async GET_CONTACTS({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`contacts`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async ADD_EDIT_CONTACT({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`contact`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body
            },{ root : true });
    },

    async GET_CONTACT({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`contact/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    // LISTS

    async ADD_EDIT_CONTACT_LIST({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`contacts/list`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body
            },{ root : true });
    },

    async GET_CONTACT_LISTS({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`contacts/lists`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async DELETE_CONTACT_LIST({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`contacts/list/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async MOVE_TO_CONTACT_LIST({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`contacts/list/move_to`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body
            },{ root : true });
    },

    // TAGS

    async SET_CONTACTS_TAGS({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`contacts/tags/set`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body
            },{ root : true });
    },




}


export default {
    namespaced:true,
    actions,
    state
}