const state = {
    content : []
}
const mutations = {

    DEBUG(state,payload) {
        state.content.push(payload)
    },
    DEBUG_AJAX(state,payload) {
        payload.IS_AJAX = true
        state.content.push(payload)
    },
    DEBUG_AJAX_SUCCESS(state,payload) {
        if ( payload === true ) {
            payload = {}
        }
        payload.IS_AJAX = true
        let data = { IS_AJAX_SUCCESS : true}
        if ( payload ) {
            data.response = payload
        }
        state.content.push(data)
    },
    DEBUG_AJAX_ERROR(state,payload) {
        payload.IS_AJAX = true
        state.content.push({ IS_AJAX_ERROR : true , error : payload  })
    }
}

export default {
    namespaced:true,
    state,
    mutations
}