const actions = {
    // AJAX HANDLING
    async AJAX_SEND_NEW({rootState}, body) {
        try {
            rootState.LOADING = true;
            var params = {
                route  :  body.route,
                method :  body.method,
                token  :  body.token
            }

            if (body.data) {
                params['body'] = body.data
            }

            if (body.business) {
                params.business = body.business
            }

            const data = await rootState.$ajax_new(params);
            rootState.LOADING = false;
            return data


        } catch (e) {
            rootState.LOADING = false;
            return e.error
        }
    },
    async AJAX_SEND({rootState,}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.business) {
                params.business = body.business
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return e.error
        }
    },

    async ADD_BUSINESS_USER_FILE({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`file/business`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },
    async ADD_SUPERADMIN_USER_FILE({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`file/entity/superadmin`,
                method:'PUT',
                data : body,
            },  {root:true});
    },
    // :: COURSES ::



    async UPLOAD_CONTENT_IMAGE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND_NEW',{
            token:rootState.auth.accessToken,
            route:`upload/user/image/${body.param}`,
            method:'POST',
            business : rootState.business.selectedBusiness.alias,
            data: {
                fileFormData : body.file
            } } , true );
    },
    async UPLOAD_PRIVATE_FILE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{
            token:rootState.auth.accessToken,
            route:`upload/private/user/file`,
            method:'POST',
            business : rootState.business.selectedBusiness.alias,
            data: {
                fileFormData : body.file,
                data : body.params
            } });
    },

    async UPLOAD_GET_SIGNED_URL({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',{
            token:rootState.auth.accessToken,
            route:`upload/signed_ulr`,
            method:'POST',
            business : rootState.business.selectedBusiness.alias,
            data : body
        }, {root: true});
    },
    async UPLOAD_GET_SIGNED_URL_SUPERADMIN({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',{
            token:rootState.auth.accessToken,
            route:`upload/signed_ulr/superadmin`,
            method:'POST',
            data : body
        }, {root: true});
    },


    async GET_PRIVATE_FILE({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',{
            token:rootState.auth.accessToken,
            route:`files/${body}`,
            method:'GET',
            business : rootState.business.selectedBusiness.alias,
            fileResponse : true
        }, {root: true});
    },
    async GET_PAGE_ELEMENT_FILE({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',{
            token:rootState.auth.accessToken,
            route:`files/avalon/${body.file}/${body.element}`,
            method:'GET',
            business : rootState.business.selectedBusiness.alias,
        }, {root: true});
    },

    async GET_PUBLIC_FILE({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',{
            token:rootState.auth.accessToken,
            route:`files/${body}/public`,
            method:'GET',
            business : rootState.business.selectedBusiness.alias,
            fileResponse : true
        }, {root: true});
    },
    async GET_USER_FILE_INFO({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',{
            token:rootState.auth.accessToken,
            route:`file/business/${body}`,
            method:'GET',
            business : rootState.business.selectedBusiness.alias,
        }, {root: true});
    },


    async GET_STUDENT_COMPONENT_FILE({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',{
            token:rootState.auth.accessToken,
            route:`files/student_component/${body.file}/${body.course}`,
            method:'GET',
            business : rootState.business.selectedBusiness.alias,
            fileResponse : true
        },{ root : true });
    },
    async GET_STUDENT_TEST_FILE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND_NEW',{
            token:rootState.auth.accessToken,
            route:`files/student_test/${body.file}/${body.test}`,
            method:'GET',
            business : rootState.business.selectedBusiness.alias,
            fileResponse : true
        });
    },
    async GET_STUDENT_REPLY_FILE_SELF({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND_NEW',{
            token:rootState.auth.accessToken,
            route:`files/student_reply/owner/${body.file}/${body.course}`,
            method:'GET',
            business : rootState.business.selectedBusiness.alias,
            fileResponse : true
        });
    },
    async GET_STUDENT_REPLY_FILE_FOR_TEACHER({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND_NEW',{
            token:rootState.auth.accessToken,
            route:`files/student_reply/teacher/${body.file}`,
            method:'GET',
            business : rootState.business.selectedBusiness.alias,
            fileResponse : true
        });
    },


    async DELETE_PRIVATE_FILE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{
            token:rootState.auth.accessToken,
            route:`files/${body}`,
            method:'DELETE',
            business : rootState.business.selectedBusiness.alias,
        });
    },

    async UPLOAD_COURSE_IMAGE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND_NEW',{
            token:rootState.auth.accessToken,
            route:`upload/course/image`,
            method:'POST',
            business : rootState.business.selectedBusiness.alias,
            data: {
                fileFormData : body.file,
                data : body.data
            } });
    },

    //student
    async UPLOAD_STUDENT_REPLY({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{
            token:rootState.auth.accessToken,
            route:`upload/private/student/reply`,
            method:'POST',
            business : rootState.business.selectedBusiness.alias,
            data: {
                fileFormData : body.file,
                data : body.params
            } });
    },


    // VIDEO
    async UPDATE_VIDEO_FORMATS({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',{
            token:rootState.auth.accessToken,
            route:`storage/video/quality`,
            method:'POST',
            business : rootState.business.selectedBusiness.alias,
            data:  body
        },{ root : true });
    },
    async ADD_VIDEO_SUBTITLE({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`file/video/subtitle`,
                business : rootState.business.selectedBusiness.alias,
                method:'PUT',
                data : body
            }, {root : true});
    },
    async DELETE_VIDEO_SUBTITLE({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`file/video/subtitle/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'DELETE',
            }, {root : true});
    },



}

export default {
    namespaced:true,
    actions,
}