export default {
    methods : {
        gAnalyticsInit(id) {
            var oldSripts = document.getElementsByClassName('gTagTag')
            oldSripts.forEach((script)=>{ script.remove() })
            var head = document.getElementsByTagName('head')[0];

            var gTagIncludeScript = document.createElement('script');
            gTagIncludeScript.src = "https://www.googletagmanager.com/gtag/js?id=" + id;
            gTagIncludeScript.async = true
            gTagIncludeScript.classList.add('gTagTag');

            var gTagMainScript = document.createElement('script');
            gTagMainScript.classList.add('gTagTag');
            var inlineScript = document.createTextNode(" window.dataLayer = window.dataLayer || [];\n" +
                " function gtag(){dataLayer.push(arguments);}\n" +
                " gtag('js', new Date());\n" +
                " gtag('config', '" + id +"');");
            gTagMainScript.appendChild(inlineScript);

            head.append(gTagIncludeScript)
            head.append(gTagMainScript)
        },
        fPixelInit(id) {

            var head = document.getElementsByTagName('head')[0];
            var script = document.createElement('script');
            script.innerHTML = `!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '${id}');
            fbq('track', 'PageView');`

            var noscript = document.createElement('noscript');
            noscript.innerHTML = '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id='+ id +'&ev=PageView&noscript=1"/>'

            head.append(script)
            head.append(noscript)
        },
    }
}