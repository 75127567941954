const state = {
    selectedLang : 'ua',
}
const actions = {

    // Enrollment

    async GET_ENROLLMENT_DOCUMENT_TYPES({rootState,dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`wsu_documents/enrollment/types`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    // Document types

    async ADD_EDIT_DOCUMENT_TYPE({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`wsu_documents/type`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body
            }, {root : true}
        );
    },
    async DELETE_DOCUMENT_TYPE({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`wsu_documents/type/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },



}

export default {
    namespaced:true,
    actions,
    state
}