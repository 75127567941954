<template>
<div>
  <h5  v-if="label"
       :style="BLOCK_TEXT_CSS( block , configStyle , 'form' , 'label' )"
     >
    {{  label  }}
  </h5>



    <!-- INPUT  -->
    <v-menu v-model="expand" offset-y  >

      <!-- Value Text  -->
      <template #activator="{ on, attrs }">
        <v-sheet
            v-on="on"
            v-bind="attrs"
            :style="`border : 1px solid ${BLOCK_COLOR_PARAM(block , 'form_border', configStyle)};`"
            :color="BLOCK_COLOR_PARAM(block , 'form_background' , configStyle) "
            class="d-flex justify-space-between"
        >
          <v-sheet
              :style="`padding : ${FONT_SIZE/3}px 0`"
              class="d-flex align-center fill-height"
              style="width: 100%; position: relative"
              color="transparent"
          >

            <!-- Placeholder  -->
            <h5 class="pointer"
                style="line-height: 1 !important"
                :style="BLOCK_TEXT_CSS(block ,  configStyle , 'form' , 'text' , null , !text ) + `;margin-left : ${FONT_SIZE/2}px;`"
            >
              {{ text || placeholder || $t('EnterText') }}
            </h5>
          </v-sheet>

          <!-- Actions -->
          <div :style="`margin-right : ${FONT_SIZE/3}px;`" class="d-flex align-center">
            <!-- Clearable Icon-->
            <v-icon :color="BLOCK_COLOR_PARAM(block , 'form_icon', configStyle)" >mdi-menu-down</v-icon>

            <v-icon
                v-if="text && clearable"
                @click="text = ''"
                :color="BLOCK_COLOR_PARAM(block , 'form_icon', configStyle)"
                class="pointer"
            >
              mdi-close-circle
            </v-icon>
          </div>

        </v-sheet>

      </template>

      <!-- Dropdown  -->
      <v-sheet :color="BLOCK_COLOR_PARAM(block , 'form_background' , configStyle) "

      >
        <v-hover v-for="(item , i) in items" :key="i"  #default="{hover}">


          <v-sheet
              @click="selectItem(item)"
              :color="hover ? '#00000011' : 'transparent'"
              class="px-3 py-1 pointer" >

            <h3 :style="BLOCK_TEXT_CSS(block ,  configStyle , 'form' , 'text' , null ) + `padding : ${FONT_SIZE/3}px 0`" >
              {{ item.text ? item.text : item }}
            </h3>

          </v-sheet>

        </v-hover>
      </v-sheet>
    </v-menu>



</div>
</template>

<script>
export default {
  name: "avalonTextField",
  props : {
    value : {
      type : String,
    },
    items : {
      type : Array,
      default() { return [] }
    },
    block : {
      type : Object,
    },
    configStyle : {
      type : String,
    },
    label : {
      type : String
    },
    placeholder : {
      type : String,
    },
    clearable : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      text : '',
      expand : false,
    }
  },
  computed:  {
    FONT_SIZE() {
      return this.BLOCK_FONT_VARIABLE(this.block, 'size' , 'form' , 'text' )
    }
  },
  watch : {
    value() {
      if ( this.value !== this.text ) {
        this.text = this.value
      }
    },
    text() {
      if ( this.value !== this.text) {
        this.$emit('input' , this.text )
      }
    }
  },
  methods : {
    selectItem(item) {
      this.text = item.value ? item.value : item
      if ( item.action ) {
        item.action()
      }
    }
  },
  mounted() {
    if ( !this.block && !this.configStyle ) {
      this.configStyle = 'light_1'
    }
    if (this.value) {
      this.text = this.value
    }
  }
}
</script>

<style scoped>
.auto-growable-input {
  width: 100%;
  resize: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  padding: 8px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
</style>