const actions = {

    // Academic groups
    async GET_APPLICATION_SPECIALTIES({rootState,dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`wsu_application/specialties`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async GET_STUDENT_APPLICATION_DOCUMENTS({rootState,dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`wsu_application/documents`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

}

export default {
    namespaced:true,
    actions,
}