import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import factory from '@/factory';

import UUID from "vue-uuid";
import notifications from "@/mixins/notifications";
import checkPermissions from "@/mixins/auth/checkPermissions";
import avalon from "@/mixins/avalon";
import formatTime from "@/mixins/formatTime";
import payments from "@/mixins/payments";
import googleMix from "@/mixins/googleMix";
import templatesConfig from "@/mixins/templatesConfig";
import debug from "@/mixins/debug";
import events from "@/mixins/events";
import wsu from "@/mixins/wsu";
import studentCourse from "@/mixins/studentCourse";
import modulesFunctions from "@/mixins/modulesFunctions";
import { VueMasonryPlugin } from "vue-masonry";
import * as VueGoogleMaps from 'vue2-google-maps'
import VueApexCharts from 'vue-apexcharts'
import VueResponsiveDash from 'vue-responsive-dash'
import device from "vue-device-detector"
import DragSelect from "drag-select-vue";
import VueHtml2Canvas from 'vue-html2canvas';
import VueShepherd from 'vue-shepherd';
import VueInputAutowidth from 'vue-input-autowidth'
import VueTextareaAutogrowDirective from 'vue-textarea-autogrow-directive'
import PortalVue from 'portal-vue';
import VueMeta from 'vue-meta'

Vue.use(VueMeta)

// Register the PortalVue component as a plugin
Vue.use(PortalVue);

Vue.use(VueTextareaAutogrowDirective)
Vue.use(VueInputAutowidth)

// global register
Vue.use(VueShepherd);
Vue.use(VueHtml2Canvas);
Vue.use(device)
Vue.use(VueApexCharts)
Vue.use(DragSelect)
Vue.use(UUID);
Vue.use(VueResponsiveDash)
Vue.use(VueMasonryPlugin);
Vue.use(VueGoogleMaps, {
  load: {
    language: 'uk',
    key: 'AIzaSyDDT5_XouOTy8gxBKvPUVA421aKji55f7w',
    libraries: 'places', // This is required if you use the Autocomplete plugin
  },
})


Vue.mixin(checkPermissions)
Vue.mixin(formatTime)
Vue.mixin(notifications)
Vue.mixin(payments)
Vue.mixin(templatesConfig)
Vue.mixin(modulesFunctions)
Vue.mixin(googleMix)
Vue.mixin(events)
Vue.mixin(debug)
Vue.mixin(avalon)
Vue.mixin(studentCourse)
Vue.mixin(wsu)


Vue.directive('windowResize', {
  inserted: function(el, binding) {
    const onResizeCallback = binding.value;
    window.addEventListener('resize', () => {
      const width = document.documentElement.clientWidth;
      const height = document.documentElement.clientHeight;
      onResizeCallback({ width, height });
    })
  }
});

Vue.config.productionTip = false

const { ajax,ajax_new,ajax_custom,router,store,socket , i18n } = factory

Vue.use(socket)

new Vue({
  vuetify,
  ajax,
  ajax_new,
  ajax_custom,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
