const state = {
    partnerReferral : null,
}
const actions = {

    // SuperAdmin
    async GET_PARTNERS({dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`partners`,
                method:'GET'
            }, {root:true });
    },
    async ADD_PARTNER_USER({dispatch},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`partner/user`,
                method:'PUT',
                data : body
            }, {root:true });
    },

    async CHECK_PARTNER_REFERRAL({dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`partner/referral/check/${body}`,
                method:'GET',
            }, {root : true}
        );
    },

    // PARTNER

    async GET_PARTNER_DATA_SELF({dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`partner/self`,
                method:'GET'
            }, {root:true });
    },





}

export default {
    namespaced:true,
    actions,
    state
}