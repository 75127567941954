export default {

    methods : {
        MONTH_DAY(time , full = true) {
            if (!time) { return '' }
            let timeData = time.split(' ')
            let month = this.$t(timeData[0] + (full ? '' : '_short') )
            let day = full ? timeData[1].replace('0','') : full
            if (timeData && timeData.length > 1 ) {
                return month  + ", " + day
            }
            return time;
        },

        formatTime(inputTime) {

            var time = new Date(inputTime)
                time = `${time.getDate()}.${time.getMonth()} - ${time.getHours()}:${time.getMinutes()}`;
            return time;

        },
        TIME_YMD(inputTime) {
            if (!inputTime) { return '' }
            const time = new Date(inputTime*1000)
            let month = time.getMonth() + 1
            month = month < 10 ? `0${month}` : month
            let date = time.getDate()
            date = date < 10 ? `0${date}` : date
            return `${time.getFullYear()}.${month}.${date}`
        },
        PARSE_TIME_STAMP(inputTime , today = false , noTime = false ) {

            var time = new Date(inputTime*1000)

            if ( isNaN(time) ) {
                return ''
            }

            let year = !this.isThisYear(time) ? `.${time.getFullYear().toString().slice(-2)}` : '';
            let month = time.getMonth()  < 10 ? ('0' + (time.getMonth() + 1)) : (time.getMonth() + 1);
            let date = time.getDate()  < 10 ? ('0' +time.getDate() ) : time.getDate();
            let hours = time.getHours()  < 10 ? ('0' +time.getHours() ) : time.getHours();
            let minutes = time.getMinutes()  < 10 ? ('0' +time.getMinutes() ) : time.getMinutes();

            if ( today && this.isToday(time) ) {
                return `${hours}:${minutes}`;
            }
            if ( !noTime ) {
                return `${date}.${month}${year} - ${hours}:${minutes}`;
            } else {
                return `${date}.${month}`;
            }


        },
        PARSE_DATE( inputTime , isDate = false , timeStamp = false , displayYear = false , displayTime = false , today = false ) {

            if ( timeStamp ) {
                inputTime = inputTime * 1000
            }
            var time = !isDate ? new Date(inputTime) : inputTime

            if ( isNaN(time) ) {
                return ''
            }

            let year = time.getFullYear()
            let month = time.getMonth() + 1
            let date = time.getDate()  < 10 ? ('0' +time.getDate() ) : time.getDate();
            let hours = time.getHours()  < 10 ? ('0' +time.getHours() ) : time.getHours();
            let minutes = time.getMinutes()  < 10 ? ('0' +time.getMinutes() ) : time.getMinutes();

            let result =  date + ' ' + this.$t(`Month_${month}`) + (!this.isThisYear(time) || displayYear ?  `, ${year}` : '')

            if ( today && this.isToday(time) ) {
                return `${hours}:${minutes}`;
            }

            if ( displayTime ) {
                result = `${result}, ${hours}:${minutes}`
            }
            return result
        },
        MONTH_DAY_TIME(timestamp , time = true , today = false) {
            return this.PARSE_DATE( timestamp , false, true , false , time , today )
        },
        MONTH_DAY_YEAR(timestamp , displayYear = true) {
            return this.PARSE_DATE( timestamp , false, true , displayYear , false )
        },

        isToday(date) {
            const today = new Date()
            return today.getDate() === date.getDate() &&
                today.getMonth() === date.getMonth() &&
                today.getFullYear() === date.getFullYear()
        },
        isThisYear(date) {
            const today = new Date()
            return today.getFullYear() === date.getFullYear()
        }
    }
}