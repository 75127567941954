const state = {
    selectedLang : 'ua',
}
const actions = {

    // Managers

    async GET_DEPARTMENT_TEACHERS({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`wsu_teacher_profiles/department/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async ADD_NEW_TEACHER_PROFILE({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`wsu_teacher_profile/new`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body
            }, {root : true}
        );
    },

    async ADD_SELECTED_TEACHER_PROFILES({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`wsu_teacher_profile/selection`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body
            }, {root : true}
        );
    },

    // Teachers

    async ADD_TEACHER_DISCIPLINE({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`wsu_teacher_profile/teacher/discipline`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body
            }, {root : true}
        );
    },

    async DELETE_TEACHER_DISCIPLINE({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`wsu_teacher_profile/teacher/discipline/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },










}

export default {
    namespaced:true,
    actions,
    state

}