export default {
    computed : {

        HOURS_PER_CREDIT() {
            return 30
        },
        EVALUATION_SELECT() {
            return [
                { text : this.$t('Exam') , value : 'exam' },
                { text : this.$t('CreditControl') , value : 'credit' },
                { text : this.$t('DifferentialCreditControl') , value : 'differential_credit' },
                { text : this.$t('CourseProject') , value : 'course_project' },
            ]
        }

    }
}