const actions = {

    // AJAX HANDLING
    async AJAX_SEND({rootState}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            return data


        } catch (e) {
            console.log(e)
            return e.error
        }
    },

    async UPLOAD_IMPORT_USERS({dispatch,rootState},body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`import/users`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body
            }, {root : true });
    },

    // Education Programs
    async UPLOAD_IMPORT_DATA({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                business : rootState.business.selectedBusiness.alias,
                route:`import/${body.type}`,
                method:'POST',
                data : {
                    fileFormData : body.file
                }
            });
    },

}

export default {
    namespaced:true,
    actions,
}