const state = {
    selectedLang : 'ua',
}
const actions = {

    // Education Programs

    async GET_EDUCATION_PROGRAMS({rootState,dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`education/education_programs`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async ADD_EDIT_EDUCATION_PROGRAM({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`education/education_program`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true}
        );
    },

    async DELETE_EDUCATION_PROGRAM({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`education/education_program/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async GET_EDUCATION_PROGRAM({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`education/education_program/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    // Education program editor / components

    async ADD_EDIT_EDUCATION_PROGRAM_COMPONENT({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`education/education_program_component`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true}
        );
    },

    async REORDER_EDUCATION_PROGRAM_COMPONENTS({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`education/education_program_components/reorder`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true}
        );
    },

    async DELETE_EDUCATION_PROGRAM_COMPONENT({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`education/education_program_component/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async ADD_EDIT_EDUCATION_PROGRAM_TOPIC({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`education/education_program_topic`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true}
        );
    },

    async DELETE_EDUCATION_PROGRAM_TOPIC({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`education/education_program_topic/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async ADD_EDIT_PRACTICE_TYPE({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`education/practice_type`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true}
        );
    },


    // Study plans

    async GET_STUDY_PLANS({rootState,dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`education/study_plans`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async ADD_EDIT_STUDY_PLAN({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`education/study_plan`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true}
        );
    },

    async DELETE_STUDY_PLAN({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`education/study_plan/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async GET_STUDY_PLAN_EDITOR({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`education/study_plan/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    // Study plan editor / components

    async ADD_EDIT_STUDY_PLAN_COMPONENT({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`education/study_plan_component`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true}
        );
    },

    async DELETE_STUDY_PLAN_COMPONENT({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`education/study_plan_component/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },



}

export default {
    namespaced:true,
    actions,
    state

}