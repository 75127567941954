// let $store;
let isProcessingQueue = [ false , false , false , false , false ];
let QUEUE = [ [] , [] , [] , [] , [] ];
let queIndex = 0;
let isRefreshing = 0;
let refreshWaitTime = 0
let refreshTimeout = 45000;
//
// function setStore(store) {
//     $store = store;
// }

function flushQueue() {
    QUEUE = [ [] , [] , [] , [] , [] ];
    queIndex = 0;
}

async function handleQue(queIndex) {
    if (isProcessingQueue[queIndex]) {
        return;
    }

    isProcessingQueue[queIndex] = true;

    while (QUEUE[queIndex].length > 0) {
        if ( isRefreshing && refreshWaitTime <= refreshTimeout  ) {
            await wait()
        } else {
            let request = QUEUE[queIndex].shift(); // Use shift to dequeue the first item
            try {
                let result = await getResult(request.params.baseURL, request.params.route, request.params.params, request.params.file);
                request.resolve(result);
            } catch (error) {
                request.reject(error); // Use reject for error handling
            }
        }

    }
    isProcessingQueue[queIndex] = false;

}
async function pushRequest(baseURL, route, params, file , resolve , reject , queIndex) {
    QUEUE[queIndex].push({
        resolve : resolve,
        reject : reject,
        params : {
            baseURL : baseURL,
            route : route,
            params : params,
            file : file
        }
    })
    await handleQue(queIndex)
}
async function handleResult(baseURL, route, params, file ) {

    if ( queIndex === 4 ) {
        queIndex = 0
    } else {
        queIndex++
    }

    return new Promise( (resolve , reject) => {
        pushRequest(baseURL, route, params, file , resolve , reject , queIndex)
    })
}

async function getResult(baseURL, route, params, file ) {
    const url =  baseURL + route
    // Wait if someone refreshing token
    while ( isRefreshing && refreshWaitTime <= refreshTimeout){
        await wait()
    }
    // Get Fresh Access token
    if ( params.headers.Authorization &&  String(params.headers.Authorization).includes('Bearer') && localStorage.getItem('access_token')) {
        params.headers.Authorization = "Bearer " + localStorage.getItem('access_token')
    }

    if ( !file ) {
        let out = await fetch(url,params ).then((resp) => resp.json() ).catch(() => {})
        if ( out.result ) {
            return out.data || true
        } else {
            if (out.error === "Expired token") {
                // Run function from start if someone refreshing
                if ( isRefreshing ) {
                    return getResult(baseURL, route, params, file )
                }

                let result = await refreshToken(baseURL)


                params.headers.Authorization = "Bearer " + result

                let out = await fetch(url,params ).then((resp) => resp.json() ).catch(() => {})

                if ( out.result ) { return out.data || true }
                if ( out.error ) {   throw new Error( out.error ) }

            } else {
                throw new Error( out.error )
            }

        }
        return out.result ? (out.data || true) : false

    } else {

        let out = await fetch(url,params ).then((resp) => resp.blob()  ).catch(() => {})

        if ( out.type === 'application/json') {
            let data = JSON.parse( await out.text() );

            if (data.error === "Expired token") {

                // Run function from start if someone refreshing
                if ( isRefreshing ) {
                    return getResult(baseURL, route, params, file )
                }

                let result = await refreshToken(baseURL)
                if ( !result ) {
                    throw new Error( 'refresh_expired' ) }
                params.headers.Authorization = "Bearer " + result

                let out = await fetch(url,params ).then((resp) => resp.blob()  ).catch(() => {})
                if ( out.type === 'application/json') {
                    let data = JSON.parse( await out.text() );
                    throw new Error( data.error )
                }

                return out

            } else {
                throw new Error( data.error )
            }
        }
        return out
    }
}
function wait(miliseconds = 10) {
    refreshWaitTime += miliseconds
    return new Promise(resolve => setTimeout(resolve, miliseconds) );
}
async function refreshToken(route) {
    isRefreshing = true
    refreshWaitTime = 0
    route = route + 'user/refresh'
    let params = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('refresh_token')}`},
    };
    let result = await fetch(route,params ).then((resp) => resp.json()  ).catch()
    result = await result ;

    if ( !result.result ) {
        // QUEUE = []
        localStorage.removeItem('access_token')
        localStorage.removeItem('refresh_token')
        refreshWaitTime = 0
        isRefreshing = false
        throw new Error( 'refresh_expired' )
    }

    localStorage.setItem('access_token',result.data.access)
    localStorage.setItem('refresh_token',result.data.refresh)
    refreshWaitTime = 0
    isRefreshing = false
    return result.data.access

}

function makeAjaxNew( baseURL, i18n ) {
    return async function ajax({route, method, token, business, returnFile , headers = '', body = ''}, file = null) {

        if ( !headers ) { headers = {} }

        if ( token !== 'public' ) {
            headers = headers ? {...headers , 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } : {'Authorization': `Bearer ${localStorage.getItem('access_token')}`} ;
        }
        if ( business ) {
            headers = {
                ...headers ,
                'Business': business
            }
        }

        let params = {
            method: method,
            headers: {
                'Content-Type': 'application/json',
                'Lang' : i18n.locale,
                ...headers},
        };


        if (body) {
            if ( body.fileFormData ) {

                let data = new FormData()
                data.append('file', body.fileFormData)

                if ( body.data ) {
                    Object.keys(body.data).forEach((paramName) => {
                        data.append(paramName,body.data[paramName])
                    })
                }

                delete params.headers['Content-Type']
                // params.headers['Content-Type'] = null
                params.body = data

            } else {

                body = JSON.stringify(body);
                body = body.replace(/’/gi, '\'');
                body = unescape(encodeURIComponent(body));
                params['body'] = JSON.stringify(window.btoa( body )); }
        }

        if ( returnFile ) {
            file = true
        }

        return await handleResult(baseURL , route, params , file);

    }

}

export default {
    makeAjaxNew,
    // setStore,
    flushQueue
}

