export default {
    methods : {
        ALERT(text = null) {
            if (!text) {
                this.notify(this.$t('NetworkError') , 'error')
            } else {
                this.notify(text , 'error')
            }
        },
        notify(text , color = null , icon ) {
            this.$store.state.notification = false

            setTimeout(() => {
                let colorSet = {
                    warning : this.wsATTENTION,
                    error : this.wsWARNING,
                    success : this.wsSUCCESS
                }
                this.$store.state.notification_icon = null
                this.$store.state.notification_text = text

                if ( icon ) {
                    this.$store.state.notification_icon = icon
                }
                if ( !icon && color === 'success' ) {
                    this.$store.state.notification_icon = 'mdi-checkbox-marked-circle-outline'
                }

                if ( color ) {
                    this.$store.state.notification_color = colorSet[color]
                } else {
                    this.$store.state.notification_color = this.wsACCENT
                }
                this.$store.state.notification = true
            })


        },
        notifyTop(title,text) {

            this.$store.state.notifications.notify = true
            this.$store.state.notifications.notify_title = title
            this.$store.state.notifications.notify_text = text
            setTimeout(() => (this.notification = false), 2000)

            var sound = new Audio('/sounds/notification.mp3')
            sound.play();


        },
    }
}