const state = {
}

const actions = {

    // STRUCTURE ENTITIES
    async GET_HR_STRUCTURE({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`hr/structure`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async GET_HR_STRUCTURE_SELECTOR({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`hr/structure/selector`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    async ADD_EDIT_HR_STRUCTURE_ENTITY({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`hr/structure/entity`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            },{ root : true });
    },
    async DELETE_HR_STRUCTURE_ENTITY({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`hr/structure/entity/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    // POSITIONS
    async GET_HR_POSITIONS({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`hr/positions`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async ADD_EDIT_HR_POSITION({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`hr/position`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            },{ root : true });
    },
    async DELETE_HR_POSITION({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`hr/position/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    // EMPLOYEES
    async ADD_EDIT_HR_EMPLOYEE({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`hr/employee`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            },{ root : true });
    },
    async DELETE_HR_EMPLOYEE({dispatch,rootState} , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`hr/employee/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },




}

export default {
    namespaced:true,
    actions,
    state
}