const state = {
    displayCourseCompletionDialog : false,
    courseCompletionId : null,
    studentCourse : {
        modules : []
    },
    course : {
        name : '',
        description : [],
        short_description : '',
        img : null,
    },
    courseNewRepliesCounter : 0,
    modules : [],
    navigationAdmin : 'editor',
    modulesScrollIndex : 0,
    modulesScroll : 0,
    selectedLang : null,

    courseHeaderAction : () => {},
    nameEditAction : null,
    courseHeaderActionText : '',
    displayCourseHeaderAction : false,
    pageWidth : '100%',
    pageTitle : '',
    pageSubTitle : null,
    pageRoot : null,
    pageRootLocation : null,
    pageIcon : 'mdi-school',
    pageIconRoute : '',
    questionBankReturnToCourse : false,
    pageFilters : [],
    pageDisplayFilters : false,
    pageFilterData : {},
    actionSelect : false,
    actionSelectItems : [],
    categoriesSelect : []

}
const actions = {
    // AJAX HANDLING
    async AJAX_SEND_NEW({rootState}, body) {
        try {
            rootState.LOADING = true;
            var params = {
                route  :  body.route,
                method :  body.method,
                token  :  body.token
            }

            if (body.data) {
                params['body'] = body.data
            }

            if (body.business) {
                params.business = body.business
            }

            const data = await rootState.$ajax_new(params);
            rootState.LOADING = false;
            return data


        } catch (e) {
            rootState.LOADING = false;
            return e.error
        }
    },

    async AJAX_SEND({rootState,}, body) {
        try {

            var params = {
                route  :  body.route,
                method :  body.method,
            }
            if (body.token !== 'public') {
                params.headers = {'Authorization': `Bearer ${body.token}`}
            }

            if (body.business) {
                params.business = body.business
            }

            if (body.data) {
                params['body'] = body.data
            }

            const data = await rootState.$ajax(params);
            if (data.result) {
                return data
            } else {
                console.log(data)
            }

        } catch (e) {
            console.log(e)
            return e.error
        }
    },

    // :: COURSES ::
    async GET_COURSES({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, {root : true});
    },
    async GET_COURSE_SELECTOR({dispatch,rootState}) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course_selector`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, {root : true});
    },


    async GET_ACTIVE_COURSES({dispatch,rootState}) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses/active`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            });
    },
    async GET_COURSES_SELECT({dispatch,rootState}) {
        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses/select`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            });
    },
    async GET_BUSINESS_COURSES({dispatch,rootState}) {

        return await dispatch('AJAX_SEND_NEW',
            {
                route:`courses/public`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            });
    },
    async GET_COURSE({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/${body.uuid}/dash/${body.lang || 'default' }`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            });
    },
    async GET_COURSE_FULL({dispatch,rootState},uuid) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/${uuid}/full`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            });
    },
    async GET_COURSE_PUBLIC({dispatch,rootState},body) {

        let uuid = body
        let lang = ''
        if ( typeof body === 'object' ) {
            uuid = body.uuid
            lang = '/' + body.lang
        }

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/${uuid}/public${lang}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            });
    },
    async GET_COURSE_EDIT({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/${body.uuid}/edit/${body.lang}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            });
    },
    async GET_COURSE_SCHEDULE({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/${body.uuid}/schedule/${body.enrollmentId}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            });
    },
    async GET_COURSE_COMMENTS_COMPONENTS({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/${body}/comments`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            });
    },

    async GET_COURSE_BUTTON_PUBLIC({dispatch,rootState},uuid) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/${uuid}/button/public`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            });
    },
    async GET_COURSE_BUTTON({dispatch,rootState},uuid) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/${uuid}/button`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            });
    },

    async ADD_COURSE({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course`,
                business : rootState.business.selectedBusiness.alias,
                method: 'PUT',
                data : body
            });
    },
    async COPY_COURSE({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/copy`,
                business : rootState.business.selectedBusiness.alias,
                method: 'PUT',
                data : body
            });
    },

    async CHANGE_COURSE_CATEGORY({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/category/change`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
                data : body
            });
    },
    async EDIT_COURSE_IMAGE({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/image/edit`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
                data : body
            });
    },
    async DELETE_COURSE({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`course/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method: 'DELETE'
            }, {root : true});
    },

    async ADD_COURSE_GROUP({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course_group`,
                business : rootState.business.selectedBusiness.alias,
                method: 'PUT',
                data : body
            },{ root : true });
    },
    async DELETE_COURSE_GROUP({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course_group/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method: 'DELETE',
            },{ root : true });
    },
    async ADD_COURSE_CATEGORY({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course_category`,
                business : rootState.business.selectedBusiness.alias,
                method: 'PUT',
                data : body
            },{ root  :true });
    },
    async DELETE_COURSE_CATEGORY({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course_category/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method: 'DELETE',
            },{ root  :true });
    },
    async GET_COURSES_CATEGORIES({dispatch,rootState ,state} ) {
        let result = await dispatch('AJAX_SEND_NEW', {
            route: `business/courses/categories`,
            method: 'GET',
            business : rootState.business.selectedBusiness.alias,
        } )

        if ( !result || result === true ) {
            return
        }
        state.categoriesSelect = result

    },
    async PUBLISH_COURSE({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/edit/publish`,
                business : rootState.business.selectedBusiness.alias,
                method: 'PUT',
                data : { uuid : body }
            },{ root: true });
    },

    // Student profiles
    async GET_COURSE_STUDENTS({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/students`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,

            });
    },
    async GET_COURSES_STUDENT({dispatch,rootState}) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses/student`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            });
    },
    async ENROLL_ON_COURSE({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/enroll`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
                data : body
            },{ root : true });
    },
    async GET_STUDENT_COURSE({dispatch,rootState},uuid) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/${uuid}/student`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            });
    },
    async CHECK_STUDENT_ENROLLMENT({dispatch,rootState},uuid) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/${uuid}/student/check`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            });
    },

    async ADD_STUDENT_PROFILE({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`student_profile`,
                business : rootState.business.selectedBusiness.alias,
                method:'PUT',
                data : body
            }, {root : true});
    },
    async DELETE_STUDENT_PROFILE({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`student_profile/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'DELETE'
            },{ root : true });
    },
    async ADD_USERS_TO_COURSE({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`student_profiles`,
                business : rootState.business.selectedBusiness.alias,
                method:'PUT',
                data : body
            }, {root : true});
    },
    async GET_COURSE_FREE_USERS_SELECT({dispatch,rootState},uuid) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`course/${uuid}/free_users/select`,
                business : rootState.business.selectedBusiness.alias,
                method: 'GET',
            }, {root : true});
    },
    async EDIT_STUDENT_PROFILE({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`student_profile`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
                data : body
            });
    },

    // :: COURSE EDITOR ::
    // Modules
    async ADD_MODULE({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/module`,
                business : rootState.business.selectedBusiness.alias,
                data : body,
                method: 'PUT',
            });
    },
    async GET_MODULE({dispatch,rootState},uuid) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/module/${uuid}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            });
    },
    async EDIT_MODULE({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/module`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
                data: body
            });
    },
    async DELETE_MODULE({dispatch,rootState},uuid) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/module/${uuid}`,
                business : rootState.business.selectedBusiness.alias,
                method: 'DELETE',
            });
    },
    async EDIT_MODULE_SCHEDULE({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/module/schedule/edit`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
                data : body
            }, {root : true});
    },
    async EDIT_MODULE_PAYMENT({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/module/payment/edit`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
                data: body
            } , {root: true});
    },

    // Topics
    async ADD_TOPIC({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/module/topic`,
                business : rootState.business.selectedBusiness.alias,
                data : body,
                method: 'PUT',
            });
    },
    async GET_TOPIC({dispatch,rootState},uuid) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/topic/${uuid}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            });
    },
    async EDIT_TOPIC({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/module/topic`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
                data : body
            });
    },
    async DELETE_TOPIC({dispatch,rootState},uuid) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/topic/${uuid}`,
                business : rootState.business.selectedBusiness.alias,
                method: 'DELETE',
            });
    },
    // Components
    async ADD_COMPONENT({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/topic/component`,
                business : rootState.business.selectedBusiness.alias,
                method: 'PUT',
                data : body
            });
    },
    async GET_COMPONENT({dispatch,rootState},uuid) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/component/${uuid}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            });
    },
    async EDIT_COMPONENT({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/topic/component`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
                data : body
            });
    },
    async PUBLISH_COMPONENT({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/topic/component/publish`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
                data : { uuid : body }
            });
    },
    async UNPUBLISH_COMPONENT({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/topic/component/unpublish/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
            });
    },
    async EDIT_COMPONENT_PAYMENT({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/topic/component/payment`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
                data : body
            } , {root: true});
    },
    async DELETE_COMPONENT({dispatch,rootState},uuid) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/component/${uuid}`,
                business : rootState.business.selectedBusiness.alias,
                method: 'DELETE',
            });
    },
    async EDIT_COMPONENT_SCHEDULE({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/topic/component/schedule`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
                data : body
            });
    },
    async SET_COMPONENT_FILE({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`course/topic/component/file`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
                data : body
            }, {root : true});
    },

    async REORDER_MODULES({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`course/modules/reorder`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
                data : body
            }, {root : true});
    },
    async REORDER_TOPICS({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`course/topics/reorder`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
                data : body
            }, {root : true});
    },
    async REORDER_COMPONENTS({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                route:`course/components/reorder`,
                business : rootState.business.selectedBusiness.alias,
                method: 'POST',
                data : body
            }, {root : true});
    },
    async EDIT_COMPONENT_RESULT({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`course/topic/component/set_passed/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method: 'GET',
            });
    },
    async GET_STUDENT_COMPONENT({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`student/component/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },

    // Lectures
    async GET_LECTURE_EDIT({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`lecture/edit/${body.uuid}/${body.lang}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            },  {root:true});
    },
    async GET_LECTURE_STUDENT({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`lecture/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },{ root : true });
    },
    async SAVE_LECTURE({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`lecture`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            });
    },
    async ADD_LECTURE_TOPIC({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`lecture/topic`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },
    async DELETE_LECTURE_TOPIC({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`lecture/topic/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },

    async EDIT_LECTURE_TOPIC_NAME({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`lecture/topic/name`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },
    async EDIT_LECTURE_TOPIC_CONTENT({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`lecture/topic/content`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },
    async ADD_LECTURE_TOPIC_FILE({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`lecture/topic/file`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});

    },
    async REMOVE_LECTURE_FILE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{
            token:rootState.auth.accessToken,
            route:`lecture/file/${body.lecture}/${body.file}`,
            method:'DELETE',
            fileResponse : true,
            business : rootState.business.selectedBusiness.alias,
        });
    },
    // Task
    async GET_TASK_EDIT({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`task/edit/${body.uuid}/${body.lang}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            },  {root:true});
    },
    async GET_TASK_STUDENT({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`task/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            });
    },
    async SAVE_TASK({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`task`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            });
    },
    async REMOVE_TASK_FILE({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',{
            token:rootState.auth.accessToken,
            route:`task/file/${body.task}/${body.file}`,
            method:'DELETE',
            fileResponse : true,
            business : rootState.business.selectedBusiness.alias,
        });
    },
    async ADD_EDIT_STUDENT_TASK_REPLY({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`task/reply/add`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root:true });
    },


    // ::  TESTS ::
    // Teacher
    async NEW_TASK_TEST({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`test/task/${body}`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
            });
    },
    async GET_TEST_EDIT({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`test/edit/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            });
    },
    async GET_TEST_PREVIEW({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`test/preview/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            });
    },

    // Test Question Sets
    async ADD_EDIT_QUESTION_SET({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`test/question_set`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            });
    },
    async DELETE_QUESTION_SET({dispatch,rootState},body) {
        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`test/question_set/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            });
    },
    // Question Banks


    async GET_QUESTION_BANK_EDIT({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`question_bank/edit/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            });
    },
    async GET_QUESTION_BANK_SHORT({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`question_bank/short/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            });
    },
    // Question Bank Question

    // Student
    async GET_TEST_STUDENT({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`test/student/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            });
    },
    async REPLY_ANSWER({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`test/reply`,
                method:'POST',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            });
    },
    async FINISH_TEST({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND',
            {
                token:rootState.auth.accessToken,
                route:`test/finish/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            });
    },
    // Schedule
    async ADD_ENROLLMENT_WAVE({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses/${body.course_id}/enrollment_wave`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            });
    },
    async GET_ENROLLMENT_WAVES({dispatch,rootState},uuid) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses/${uuid}/enrollment_waves`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,

            });
    },
    async GET_ENROLLMENT_WAVES_SELECT({dispatch,rootState},uuid) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses/${uuid}/enrollment_waves/select`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,

            });
    },
    async GET_ENROLLMENT_WAVES_STUDENT({dispatch,rootState},uuid) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses/${uuid}/enrollment_waves/student`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,

            });
    },
    async GET_ENROLLMENT_WAVE({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses/enrollment_wave/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            });

    },
    async DELETE_ENROLLMENT_WAVE({dispatch,rootState},body) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses/enrollment_wave/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            });

    },
    // Payment Plans
    async ADD_PAYMENT_PLAN({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses/${body.course_id}/payment_plan`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root : true});
    },
    async ADD_PAYMENT_PLAN_AVALON({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses/${body.course_id}/payment_plan/avalon`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root : true});
    },
    async GET_PAYMENT_PLANS( {dispatch,rootState}, body ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses/${body.uuid}/payment_plans/${body.lang}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true});
    },
    async GET_COURSE_PAYMENT_PLANS_SELECT({dispatch,rootState},uuid) {

        return await dispatch('AJAX_SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses/${uuid}/payment_plans/select`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,

            });
    },
    async GET_PAYMENT_PLAN({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses/payment_plan/${body.uuid}/${body.lang}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true});

    },
    async DELETE_PAYMENT_PLAN({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses/payment_plan/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true});

    },


    // COMMENTS

    async SEND_COMPONENT_COMMENT({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses/component/comment`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root : true});
    },
    async SEND_COMPONENT_COMMENT_ADMIN({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses/component/comment/admin`,
                method:'PUT',
                data : body,
                business : rootState.business.selectedBusiness.alias,
            }, {root : true});
    },
    async GET_COURSE_COMPONENT_COMMENTS_ADMIN({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses/component/comments/${body}/admin`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true});
    },
    async DELETE_COMMENT_ADMIN({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses/component/comment/${body}/admin`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true});
    },
    async DELETE_COMMENT_STUDENT({dispatch,rootState},body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`courses/component/comment/${body}/student`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true});
    }



}
const mutations = {
    INIT_PAGE(state, payload = null) {

        state.pageWidth = '100%'


        if ( !payload ) {
            return
        }
        let { width , button, action , title , subtitle, root , rootLocation, icon, icon_route , name_edit_action , no_flush , filters , actionSelect } = payload
        state.pageSubTitle = subtitle || null
        state.pageRoot = root || null
        state.pageRootLocation = rootLocation || null

        if ( !no_flush ) {
            state.displayCourseHeaderAction = false
            state.courseHeaderAction = () => {}
            state.courseHeaderActionText = ''
            state.pageIcon = 'mdi-school'
            state.pageIconRoute = ''
            state.nameEditAction = null
            state.pageDisplayFilters = false
            state.pageFilters = []
            state.actionSelect = false
            state.actionSelectItems = []
            state.pageFilterData = {}
        }

        if ( actionSelect ) {
            state.actionSelect = true
            state.actionSelectItems = actionSelect
        }

        if ( title ) {
            state.pageTitle = title
        }
        if ( icon ) {
            state.pageIcon = icon
        }
        if ( icon_route ) {
            state.pageIconRoute = icon_route
        }
        if ( name_edit_action ) {
            state.nameEditAction = name_edit_action
        }
        if ( filters ) {

            state.pageFilters = filters
            state.pageDisplayFilters = true

        }

        if ( width ) {
            if ( width === '100%' ) {
                state.pageWidth = width
            } else {
                state.pageWidth = width + 'px'
            }

        }

        if ( button ) {
            state.displayCourseHeaderAction = true
            state.courseHeaderActionText = button
            state.courseHeaderAction = action
        }

    }
}

export default {
    namespaced:true,
    actions,
    mutations,
    state
}