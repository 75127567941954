<template>
  <div :style="`position: fixed; bottom: ${display ? '0' : '25px'} ;right: ${display ? '0' : '25px'};`">
    <v-btn  v-if="!display" @click="display = true" :color="wsATTENTION" large icon><v-icon large>mdi-bug-check</v-icon></v-btn>
    <v-sheet v-if="display" dark height="100vh" width="900" class="pa-4 overflow-y-auto">
      <div class="d-flex justify-space-between align-center">
        <code> Fine Front Debug Interface </code> <br>
        <v-btn  @click="display = false" large icon><v-icon large>mdi-chevron-down</v-icon></v-btn>
      </div>
      <v-divider class="mb-5" />

<!--      <code> TOKEN : {{ token }}</code> <br>-->
<!--      <code> REFRESH : {{ refresh }}</code><br>-->
<!--      <v-divider></v-divider>-->


      <div class="fill-height ">
        <div v-for="(data,i) in content" :key="i">

          <template v-if=" typeof data === 'object' && !Array.isArray(data)">
            <!-- AJAX ROUTE DEBUG-->
            <div v-if="data.IS_AJAX" >

              <code> {{ i +1 }}. {{ data.method }} {{ data.route }} </code>

              <div v-if="data.body">
                <code>{</code>
                <div v-for="(value,key) in data.body" :key="i + key">
                  <code class="ml-4"><strong> {{ key }}</strong> :: {{ value }}</code><br>
                </div>
                <code>}</code>
              </div>
            </div>
            <div v-else-if="data.IS_AJAX_SUCCESS" >
              <code>{</code>
              <div v-for="(value,key) in data.response" :key="i + key">
                <code> <strong>{{ key }}</strong> {{ value }}</code><br>
              </div>
              <code>}</code>
            </div>
            <div v-else-if="data.IS_AJAX_ERROR" >
              <code style="color : red">{{ data.error }}</code>
            </div>

            <!-- OBJECT DEBUG-->
            <div v-else>
              <div v-for="(value,key) in data" :key="i + key">
                <code> <strong>{{ key }}</strong> :: {{ value }}</code><br>
              </div>
            </div>

          </template>


          <code v-else>{{ i+1 }}. {{ data }}</code><br>
        </div>
      </div>



      <v-btn text @click="refreshLocalstorage">Refresh Local storage</v-btn>
      <v-btn text @click="DEBUG('testing debug messaging')">Debug</v-btn>
    </v-sheet>
  </div>
</template>

<script>
import {mapState} from "vuex";
export default {
  name: "debugInterface",
  data() {
    return {
      display : false,
      token : '',
      refresh : ''
    }
  },
  computed : {
    ...mapState('debug',['content']),
    localStorage_TOKEN() {
      return  localStorage.getItem('access_token')
    },
    localStorage_REFRESH() {
      return  localStorage.getItem('refresh_token')
    }
  },
  methods : {
    refreshLocalstorage() {
      this.token = localStorage.getItem('access_token')
      this.refresh = localStorage.getItem('refresh_token')
    }
  },
  mounted() {
    this.refreshLocalstorage()
    console.log(this.$route)
  }
}
</script>

<style scoped>

</style>