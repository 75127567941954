const actions = {

    // Admin functions

    async GET_SCHEDULES({rootState,dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`schedules`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async ADD_EDIT_SCHEDULE({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`schedule`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true}
        );
    },

    async DELETE_EDUCATION_PROGRAM({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`schedule/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async GET_SCHEDULE_EDITOR({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`schedule/${body}/editor`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async ADD_SCHEDULE_PAIR({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`schedule/pair`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true}
        );
    },

    async EDIT_SCHEDULE_PAIR({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`schedule/pair`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true}
        );
    },

    async ADD_SCHEDULE_PAIR_SUBGROUP({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`schedule/pair/subgroup`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true}
        );
    },

    async DELETE_SCHEDULE_PAIR({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`schedule/pair/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async EDIT_SCHEDULE_PAIR_DATA({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`schedule/pair/data`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true}
        );
    },


    // Teachers

    async GET_TEACHER_SCHEDULE({rootState,dispatch} ) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`schedule/teacher`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },




}

export default {
    namespaced:true,
    actions,
}