export default {
    computed : {
        IS_DEV() {
            return process.env.VUE_APP_MODE === 'dev'
        },
    },
    methods : {
        DEBUG(text) {
            if ( !this.IS_DEV ) {
                return
            }
            this.$store.state.debug.content.push(text)
        }
    }
}