<template>
  <v-sheet color="transparent"
           :width="fillArea ? '100%' : width"
           :height="height"
           :class="[{'d-flex' : !fillArea} ,{'fill-height' : fillArea && !height} ]"
  >
    <v-hover #default="{hover}">
      <v-sheet
          v-ripple="buttonStyle !== 'text'"
          @click="$emit('click')"
          :class="[
              {'fill-height' : fillArea},
              {'py-2' : !large} ,
              {'py-4' : large},
              {'px-4' : buttonStyle !== 'text'},
              {'justify-center' : buttonStyle !== 'text' }
          ]"
          class="d-flex  align-center pointer"
          :style="buttonCss(hover)"
      >
        <div class="d-flex align-center"
             :class="[{'justify-space-between' : iconRight && buttonStyle !== ' text'}]"
        >
          <v-icon
              v-if="icon"
              :color="BLOCK_COLOR_PARAM(block , buttonTextColorParam , blockStyle  ) + (hover ? '66' : '')"
              :size="BLOCK_FONT_VARIABLE(block , 'size' , 'buttons' , 'text' )*1.2*SCALE_COEFFICIENT"
              class="mr-2"
          >
            {{  icon  }}
          </v-icon>
          <h4  :key="SCALE_COEFFICIENT"  :style="BLOCK_TEXT_CSS(block , blockStyle , 'buttons' , 'text' , buttonTextColorParam , hover, DEFAULT_BLOCK_WIDTH)">
            {{ text || $t("Button") }}
          </h4>
          <v-icon
              v-if="iconRight"
              :color="BLOCK_COLOR_PARAM(block , buttonTextColorParam , blockStyle   ) + (hover ? '66' : '')"
              :size="BLOCK_FONT_VARIABLE(block , 'size' , 'buttons' , 'text' )*1.2*SCALE_COEFFICIENT"
              class="ml-2"
          >
            {{  iconRight  }}
          </v-icon>

        </div>

      </v-sheet>
    </v-hover>

  </v-sheet>



</template>

<script>
export default {
  name: "avalonButton",
  props : {
    text : {
      type : String
    },
    icon : {
      type : String
    },
    iconRight : {
      type : String
    },
    buttonStyle : {
      type : String,
      default : 'primary'
    },
    blockStyle : {
      type : String,
      default : 'light_1'
    },
    radius : {
      type : Number,
    },
    fillArea : {
      type : Boolean,
      default : false
    },
    large : {
      type : Boolean,
      default : false
    },
    block : {
      type : Object,
      default() {
        return { config : {}}
      }
    },
    width : {
      type : [String , Number]
    },
    height : {
      type : [String , Number]
    },
    primaryBackgroundParam : {
      type : String
    },
    primaryTextColorParam : {
      type : String
    },
    secondaryBackgroundParam : {
      type : String
    },
    secondaryTextColorParam : {
      type : String
    }
  },
  computed : {
    buttonTextColorParam() {

      if ( this.buttonStyle === 'primary') {
        return this.primaryTextColorParam || 'button_text'
      } else if ( this.buttonStyle === 'secondary' || this.buttonStyle === 'text') {
        return this.secondaryTextColorParam|| 'button_text_secondary'
      }

      return 'button_text'
    }
  },
  methods : {
    buttonCss(hover) {

      let style = ''

      if ( this.radius ) {
        style += `border-radius: ${this.radius  || 4}px;`
      }

      if (this.buttonStyle === 'primary') {
        let color = this.BLOCK_COLOR_PARAM(this.block , this.primaryBackgroundParam || 'button_bg' , this.blockStyle )
        color = color + (hover ? 'bb' : '')
        style += `background-color : ${color};`
        style += `border : 2px solid ${color} !important; `
      }

      if ( this.buttonStyle === 'secondary' ) {
        let color = this.BLOCK_COLOR_PARAM(this.block , this.secondaryBackgroundParam || 'button_bg_secondary' , this.blockStyle )
        color = color + (hover ? 'bb' : '')
        style += `border : 2px solid ${color} !important; `
        style += `background-color : transparent;`
      }

      if ( this.buttonStyle === 'text' ) {
        style += `background-color : transparent;`
      }


      return style

    }
  }
}
</script>

<style scoped>

</style>