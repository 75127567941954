const state = {
    selectedLang : 'ua',
    selectedEntity : {},
    selectedEntityType : null,
    selectedEntityId : null,
    selectedEntityName : null,
    returnEntityType : [],
    returnEntityId : [],
    returnEntityName : [],
}
const actions = {


    async GET_STRUCTURE_SELECTOR({rootState,dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`structure/selector`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async GET_INSTITUTES({rootState,dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`structure/institutes`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },
    async ADD_INSTITUTE({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`structure/institute`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true}
        );
    },
    async DELETE_INSTITUTE({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`structure/institute/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async GET_FACULTIES({rootState,dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`structure/faculties`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },
    async ADD_FACULTY({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`structure/faculty`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true}
        );
    },
    async DELETE_FACULTY({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`structure/faculty/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async GET_DEPARTMENTS({rootState,dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`structure/departments`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },
    async ADD_DEPARTMENT({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`structure/department`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true}
        );
    },
    async DELETE_DEPARTMENT({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`structure/department/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async GET_SPECIALTIES({rootState,dispatch}) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`structure/specialties`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },
    async ADD_SPECIALTY({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`structure/specialty`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true}
        );
    },
    async DELETE_SPECIALTY({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`structure/specialty/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async GET_DISCIPLINES({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`structure/disciplines`,
                method:'POST',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true}
        );
    },
    async ADD_EDIT_DISCIPLINE({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`structure/discipline`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true}
        );
    },
    async DELETE_DISCIPLINE({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`structure/discipline/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

    async GET_ACADEMIC_GROUPS({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`structure/academic_groups/${body}`,
                method:'GET',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },
    async ADD_EDIT_ACADEMIC_GROUP({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`structure/academic_group`,
                method:'PUT',
                business : rootState.business.selectedBusiness.alias,
                data : body,
            }, {root : true}
        );
    },
    async DELETE_ACADEMIC_GROUP({rootState,dispatch} , body) {
        return await dispatch('ajax/SEND_NEW',
            {
                route:`structure/academic_group/${body}`,
                method:'DELETE',
                business : rootState.business.selectedBusiness.alias,
            }, {root : true}
        );
    },

}

export default {
    namespaced:true,
    actions,
    state

}