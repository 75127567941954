var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{class:[{'d-flex' : !_vm.fillArea} ,{'fill-height' : _vm.fillArea && !_vm.height} ],attrs:{"color":"transparent","width":_vm.fillArea ? '100%' : _vm.width,"height":_vm.height}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{directives:[{name:"ripple",rawName:"v-ripple",value:(_vm.buttonStyle !== 'text'),expression:"buttonStyle !== 'text'"}],staticClass:"d-flex  align-center pointer",class:[
            {'fill-height' : _vm.fillArea},
            {'py-2' : !_vm.large} ,
            {'py-4' : _vm.large},
            {'px-4' : _vm.buttonStyle !== 'text'},
            {'justify-center' : _vm.buttonStyle !== 'text' }
        ],style:(_vm.buttonCss(hover)),on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"d-flex align-center",class:[{'justify-space-between' : _vm.iconRight && _vm.buttonStyle !== ' text'}]},[(_vm.icon)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.BLOCK_COLOR_PARAM(_vm.block , _vm.buttonTextColorParam , _vm.blockStyle  ) + (hover ? '66' : ''),"size":_vm.BLOCK_FONT_VARIABLE(_vm.block , 'size' , 'buttons' , 'text' )*1.2*_vm.SCALE_COEFFICIENT}},[_vm._v(" "+_vm._s(_vm.icon)+" ")]):_vm._e(),_c('h4',{key:_vm.SCALE_COEFFICIENT,style:(_vm.BLOCK_TEXT_CSS(_vm.block , _vm.blockStyle , 'buttons' , 'text' , _vm.buttonTextColorParam , hover, _vm.DEFAULT_BLOCK_WIDTH))},[_vm._v(" "+_vm._s(_vm.text || _vm.$t("Button"))+" ")]),(_vm.iconRight)?_c('v-icon',{staticClass:"ml-2",attrs:{"color":_vm.BLOCK_COLOR_PARAM(_vm.block , _vm.buttonTextColorParam , _vm.blockStyle   ) + (hover ? '66' : ''),"size":_vm.BLOCK_FONT_VARIABLE(_vm.block , 'size' , 'buttons' , 'text' )*1.2*_vm.SCALE_COEFFICIENT}},[_vm._v(" "+_vm._s(_vm.iconRight)+" ")]):_vm._e()],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }