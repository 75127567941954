const state = {

    //dialogs
    displayPageDialog : false,
    displayPageCopyDialog : false,
    displayPageDeleteDialog : false,
    displayNewsDialog : false,
    newPage : false,
    newNews : true,
    copyPage : false,
    selectedPage :  null,
    currentPage : null,
    selectedCourse : null,
    selectedNewsAlias : null,
    course : {},

    newBlockNumber : null,
    newBlockId : null,
    blockNumberUpdateDirection : 0,
    blockMoveData : {},
    blockMoveTargetData : {},
    updateElementsArray : [],
}
const actions = {

    async ADD_PAGE({ dispatch,rootState } , body) {

        let result = await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/page`,
                business : rootState.business.selectedBusiness.alias,
                method:'PUT',
                data : body
            }, { root : true});
        if ( !result ) {
            return false
        }
        if ( !body.course ) {
            rootState.avalon.pagesSelect.push(result)
        } else {
            let index = rootState.avalon.coursesSelect.findIndex(el => el.value === body.course)
            if ( index !== -1 ) {

                rootState.avalon.coursesSelect[index].page[body.lang] = result.id

                if (!rootState.avalon.coursesSelect[index].page_alias) {
                    rootState.avalon.coursesSelect[index].page_alias = result.alias
                }

            }
        }

        return result
    },
    async CHECK_ALIAS({ dispatch,rootState } , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/check_alias/${body.alias}/${body.lang}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET',
            }, { root : true});

    },
    async GET_PAGE_EDIT({ dispatch,rootState }, body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/page/editor/${body.id}/${body.lang}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },
    async GET_TEMPLATE_PAGE_EDIT({ dispatch,rootState }, body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/admin/page/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },
    async GET_PAGE_PUBLIC({ dispatch,rootState }, body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/page/public/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },
    async GET_HOMEPAGE_PUBLIC({ dispatch,rootState } ) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/homepage/public`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET'
            }, { root : true});

    },


    async EDIT_PAGE({dispatch,rootState}, body) {

        return  await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/page`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
                data : body
            }, { root : true});

    },
    async DELETE_PAGE({dispatch,rootState}, body) {

        return  await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/page/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'DELETE',
            }, { root : true});

    },

    // Blocks
    async ADD_BLOCK({ dispatch,rootState } , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/page/block`,
                business : rootState.business.selectedBusiness.alias,
                method:'PUT',
                data : body
            }, { root : true});

    },
    async GET_BLOCK_EDITOR({ dispatch,rootState } , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/page/block/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'GET',
            }, { root : true});

    },
    async EDIT_BLOCK_DRAFT({ dispatch,rootState } , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/page/block`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
                data : body
            }, { root : true});

    },

    async COPY_BLOCK({ dispatch,rootState } , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/page/block/copy`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
                data : body,
            }, { root : true});

    },
    async MOVE_BLOCK({ dispatch,rootState } , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/page/block/move`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
                data : body,
            }, { root : true});

    },
    async DELETE_BLOCK({ dispatch,rootState } , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/page/block/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'DELETE'
            }, { root : true});

    },
    async ADD_ELEMENT({ dispatch,rootState } , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/page/block/element`,
                business : rootState.business.selectedBusiness.alias,
                method:'PUT',
                data : body
            }, { root : true});

    },
    async EDIT_ELEMENT({ dispatch,rootState } , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/page/block/element`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
                data : body
            }, { root : true});

    },
    async EDIT_ELEMENTS_ARRAY({ dispatch,rootState } , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/page/block/elements_array`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
                data : body
            }, { root : true});

    },


    async DELETE_ELEMENT({ dispatch,rootState } , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/page/block/element/${body}`,
                business : rootState.business.selectedBusiness.alias,
                method:'DELETE',
            }, { root : true});

    },
    async DUPLICATE_ELEMENT({ dispatch,rootState } , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/page/block/element/${body.id}/duplicate`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
                data : body
            }, { root : true});

    },

    async GET_DYNAMIC_BLOCK_ITEMS({ dispatch,rootState } , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/page/block/dynamic_data`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST',
                data : body
            }, { root : true});

    },

    async GET_DYNAMIC_BLOCK_CATEGORIES({ dispatch,rootState } , body) {

        return await dispatch('ajax/SEND_NEW',
            {
                token:rootState.auth.accessToken,
                route:`avalon/page/block/dynamic_data/categories/${body.source}${body.lang ? `/${body.lang}` : ''}`,
                business : rootState.business.selectedBusiness.alias,
                method:'POST'
            }, { root : true});

    }

}
const mutations = {
    // Pages
    OPEN_NEW_PAGE(state,payload) {
        state.selectedCourse = payload || null
        state.selectedPage = null
        state.newPage = true
        state.copyPage = false
        state.displayPageDialog = true
    },
    OPEN_EDIT_PAGE(state,payload) {
        state.newPage = false
        state.selectedPage = payload
        state.copyPage = false
        state.displayPageDialog = true
    },
    OPEN_COPY_PAGE(state,payload) {
        state.selectedPage = payload
        state.copyPage = true
        state.displayPageDialog = true
    },
    OPEN_DELETE_PAGE(state,payload) {
        state.selectedPage = payload
        state.displayPageDeleteDialog = true
    },
    // News
    OPEN_NEW_NEWS(state) {
        state.newNews = true
        state.copyNews = false
        state.displayNewsDialog = true
    },
    OPEN_EDIT_NEWS(state , payload) {
        state.newNews = false
        state.copyNews = false
        state.displayNewsDialog = true
        state.selectedNewsAlias = payload
    },
    OPEN_COPY_NEWS(state , payload) {
        state.newNews = true
        state.copyNews = true
        state.displayNewsDialog = true
        state.selectedNewsAlias = payload
    },
}

export default {
    namespaced:true,
    actions,
    mutations,
    state

}